import React from "react";
import ContentEditable from "react-contenteditable";
import { BanknotesIcon, FaceSmileIcon } from "@heroicons/react/24/solid";
import useMessage from "../../hooks/useMessage";
import useUser from "../../hooks/useUser";
import IconButton from "../IconButton";
import EmotePicker from "../EmotePicker";
import Tip from "../Tip";
import styles from "./Input.module.scss";

type Props = {
  onSend: (message: string) => void;
};

function Input({ onSend }: Props) {
  const user = useUser();
  const message = useMessage();

  const [showEmotePicker, setShowEmotePicker] = React.useState<boolean>(false);
  const [showTip, setShowTip] = React.useState<boolean>(false);

  const inputRef = React.useRef<HTMLDivElement>(null);

  const clear = () => {
    if (inputRef.current) {
      inputRef.current.innerHTML = "";
    }
  };

  const send = () => {
    const value = inputRef.current?.innerHTML;

    if (value && value.length > 0) {
      onSend(message.parse(value));
      clear();
    }
  };

  return (
    <>
      {showEmotePicker && (
        <EmotePicker
          onPick={(emote) => {
            for (const element of message.format(emote)) {
              inputRef.current?.append(element);
            }
          }}
          onClose={() => setShowEmotePicker(false)}
        />
      )}
      {showTip && <Tip onClose={() => setShowTip(false)} />}
      <div className={styles.container}>
        <div className={styles.input_container}>
          <ContentEditable
            innerRef={inputRef}
            html={inputRef.current?.innerHTML || ""}
            disabled={!user || showTip}
            onChange={() => {}}
            placeholder={
              user ? "Envie uma mensagem" : "Faça login para conversar"
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                send();
              }
            }}
          />
        </div>
        {Boolean(user) && (
          <div className={styles.actions_container}>
            {/*<IconButton*/}
            {/*  icon={<BanknotesIcon />}*/}
            {/*  onClick={() =>*/}
            {/*    setShowTip((current) => {*/}
            {/*      const value = !current;*/}

            {/*      if (value) {*/}
            {/*        setShowEmotePicker(false);*/}
            {/*        clear();*/}
            {/*      }*/}

            {/*      return value;*/}
            {/*    })*/}
            {/*  }*/}
            {/*/>*/}
            <IconButton
              icon={<FaceSmileIcon />}
              onClick={(e) =>
                setShowEmotePicker((current) => {
                  e.preventDefault();
                  const value = !current;

                  if (value) {
                    setShowTip(false);
                  }

                  return value;
                })
              }
            />
            <button className={styles.send_button} onClick={send}>
              Enviar
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default Input;
