import { PropsWithChildren } from "react";
import styles from "./Modal.module.scss";
import { XMarkIcon } from "@heroicons/react/24/solid";
import IconButton from "../IconButton";
import ReactDOM from "react-dom";

type Props = {
  position: "top" | "bottom";
  title?: string;
  onClose: () => void;
};

function Modal({
  position,
  title,
  onClose,
  children,
}: PropsWithChildren<Props>) {
  return ReactDOM.createPortal(
    <div
      className={styles.modal}
      style={position === "top" ? { top: "5px" } : { bottom: "-5px" }}
    >
      {title && <p className={styles.title}>{title}</p>}
      <div className={styles.close_button}>
        <IconButton icon={<XMarkIcon />} onClick={onClose} />
      </div>
      {children}
    </div>,
    document.getElementById("modal-wrapper")!
  );
}

export default Modal;
