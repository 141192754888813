import React from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { ChatMessage } from "../types";

export type IRCMessage = {
  raw: string;
  tags: Record<string, string | boolean>;
  prefix: string;
  username: string;
  command: string;
  params: string[];
};

export type IRCHook = {
  readyState: ReadyState;
  notice?: string;
  messages: ChatMessage[];
  authenticate: (username: string, token: string) => void;
  join: (channel: string) => void;
  send: (channel: string, message: string) => void;
};

const parseMessage = (line: string): IRCMessage | null => {
  const message: IRCMessage = {
    raw: line,
    tags: {},
    prefix: "",
    username: "",
    command: "",
    params: [],
  };

  // Position and nextspace are used by the parser as a reference
  let position = 0;
  let nextspace = 0;

  // The first thing we check for is IRCv3.2 message tags.
  // http://ircv3.atheme.org/specification/message-tags-3.2
  if (line.charCodeAt(0) === 64) {
    nextspace = line.indexOf(" ");

    // Malformed IRC message
    if (nextspace === -1) {
      return null;
    }

    // Tags are split by a semicolon
    const rawTags = line.slice(1, nextspace).split(";");

    for (let i = 0; i < rawTags.length; i++) {
      // Tags delimited by an equals sign are key=value tags.
      // If there's no equals, we assign the tag a value of true.
      const tag = rawTags[i];
      const pair = tag.split("=");
      message.tags[pair[0]] = tag.slice(tag.indexOf("=") + 1) || true;
    }

    position = nextspace + 1;
  }

  // Skip any trailing whitespace
  while (line.charCodeAt(position) === 32) {
    position++;
  }

  // Extract the message's prefix if present. Prefixes are prepended with a colon
  if (line.charCodeAt(position) === 58) {
    nextspace = line.indexOf(" ", position);

    // If there's nothing after the prefix, deem this message to be malformed.
    if (nextspace === -1) {
      return null;
    }

    message.prefix = line.slice(position + 1, nextspace);
    position = nextspace + 1;

    // Skip any trailing whitespace
    while (line.charCodeAt(position) === 32) {
      position++;
    }
  }

  // Extract username from message prefix
  if (message.prefix.length > 0) {
    const separator = message.prefix.indexOf("!");

    if (separator >= 0) {
      message.username = message.prefix.slice(0, separator);
    }
  }

  nextspace = line.indexOf(" ", position);

  // If there's no more whitespace left, extract everything from the
  // current position to the end of the string as the command
  if (nextspace === -1) {
    if (line.length > position) {
      message.command = line.slice(position);
      return message;
    }

    return null;
  }

  // Else, the command is the current position up to the next space. After
  // that, we expect some parameters.
  message.command = line.slice(position, nextspace);

  position = nextspace + 1;

  // Skip any trailing whitespace
  while (line.charCodeAt(position) === 32) {
    position++;
  }

  while (position < line.length) {
    nextspace = line.indexOf(" ", position);

    // If the character is a colon, we've got a trailing parameter.
    // At this point, there are no extra params, so we push everything
    // from after the colon to the end of the string, to the params array
    // and break out of the loop.
    if (line.charCodeAt(position) === 58) {
      message.params.push(line.slice(position + 1));
      break;
    }

    // If we still have some whitespace.
    if (nextspace !== -1) {
      // Push whatever's between the current position and the next
      // space to the params array.
      message.params.push(line.slice(position, nextspace));
      position = nextspace + 1;

      // Skip any trailing whitespace and continue looping.
      while (line.charCodeAt(position) === 32) {
        position++;
      }

      continue;
    }

    // If we don't have any more whitespace and the param isn't trailing,
    // push everything remaining to the params array.
    if (nextspace === -1) {
      message.params.push(line.slice(position));
      break;
    }
  }

  return message;
};

const parseBadges = (input: string): Record<string, boolean> => {
  const badges: Record<string, boolean> = {};

  for (const item of input.split(",")) {
    const [name, value] = item.split("/");
    badges[name] = value === "1";
  }

  return badges;
};

const useIRC = (): IRCHook => {
  const [notice, setNotice] = React.useState<string>();
  const [messages, setMessages] = React.useState<ChatMessage[]>([]);

  const { sendMessage, readyState } = useWebSocket<ChatMessage>(
    process.env.REACT_APP_LOVEPIX_IRC_WS_URL!,
    {
      retryOnError: true,
      shouldReconnect: () => true,
      onMessage: (event) => {
        const message = parseMessage(event.data);

        if (message?.command === "PRIVMSG") {
          setMessages((current) => {
            current.push({
              id: message.tags["id"] as string,
              channel: message.params[0].replace("#", ""),
              username: message.username,
              message: message.params[1],
              color: message.tags["color"] as string,
              badges: parseBadges(message.tags["badges"] as string),
              subscriber: message.tags["subscriber"] === "1",
              moderator: message.tags["mod"] === "1",
              tags: message.tags,
            });

            if (current.length > 100) {
              current.shift();
            }

            return [...current];
          });
        } else if (message?.command === "CLEARCHAT") {
          const username =
            message.params.length === 2
              ? message.params[1].replace(":", "").trim()
              : null;

          if (username && username !== "") {
            setMessages((current) =>
              current.map((message) => ({
                ...message,
                deleted: message.username === username,
              }))
            );
          } else {
            setMessages([]);
          }
        } else if (message?.command === "NOTICE") {
          const notice =
            message.params.length === 1 ? message.params[0] : message.params[1];
          setNotice(notice.replace(":", ""));
        }
      },
    }
  );

  React.useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      const pingInterval = setInterval(() => {
        sendMessage("PING :irc.lovepix.dev");
      }, 10_000);

      return () => clearInterval(pingInterval);
    }
  }, [readyState, sendMessage]);

  React.useEffect(() => {
    if (!notice) return;
    const timeout = setTimeout(() => setNotice(undefined), 3_000);
    return () => clearTimeout(timeout);
  }, [notice]);

  return {
    readyState,
    notice,
    messages,
    authenticate: (username: string, token: string) => {
      sendMessage(`PASS bearer:${token}`);
    },
    join: (channel: string) => {
      sendMessage(`JOIN #${channel}`);
    },
    send: (channel: string, message: string) => {
      sendMessage(`PRIVMSG #${channel} :${message}`);
    },
  };
};

export default useIRC;
