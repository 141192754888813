import React from "react";
import Message from "../Message";
import { ChatMessage } from "../../types";
import styles from "./History.module.scss";

type Props = {
  messages: ChatMessage[];
};

function History({ messages }: Props) {
  const [autoScroll, setAutoScroll] = React.useState<boolean>(true);
  const scrollRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!scrollRef.current) return;

    const element = scrollRef.current;

    if (autoScroll) {
      element.scrollTo(0, element.scrollHeight);
    }

    const listener = (e: Event) => {
      const isNearEnd =
        element.scrollHeight - element.scrollTop <= element.clientHeight + 50;
      setAutoScroll(isNearEnd);
    };

    element.addEventListener("scroll", listener);

    return () => {
      element.removeEventListener("scroll", listener);
    };
  }, [messages, autoScroll]);

  return (
    <div className={styles.container}>
      <div className={styles.scroll_container} ref={scrollRef}>
        {messages.map((message) => (
          <Message key={message.id} message={message} />
        ))}
      </div>
    </div>
  );
}

export default History;
