import styles from "./Notice.module.scss";

type Props = {
  message: string;
};

function Notice({ message }: Props) {
  return (
    <div className={styles.container}>
      <p>{message}</p>
    </div>
  );
}

export default Notice;
