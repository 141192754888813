import axios from "axios";

const apiUri = process.env.REACT_APP_LOVEPIX_API_URL;

const buildUrl = (path: string) => `${apiUri}${path}`;

const client = axios.create({
  baseURL: apiUri,
  timeout: 5_000,
  withCredentials: true,
});

const useApi = () => {
  return {
    buildUrl,
    get: client.get,
    post: client.post,
    put: client.put,
    patch: client.patch,
    delete: client.delete,
  };
};

export default useApi;
