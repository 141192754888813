import emotes, { EmoteConfig } from "../data/emotes";

const useMessage = () => {
  const resolveEmote = (word: string): EmoteConfig | null => {
    return emotes[word] || null;
  };

  const render = (word: string): string | Node => {
    const emote = resolveEmote(word);

    if (emote) {
      const node = document.createElement("img");
      node.src = `/images/emotes/${emote.image}-56.png`;
      node.alt = emote.name;
      node.className = `emote emote-${word}`;

      return node;
    }

    return word;
  };

  const format = (value: string): (string | Node)[] => {
    const words = value.trim().replace("\r\n", "").split(" ");

    const result: (string | Node)[] = [];

    for (const word of Array.from(words.values())) {
      result.push(render(word));
    }

    return result;
  };

  const parse = (value: string): string => {
    console.log("parse", value);
    value = value.replace(
      /<img([a-zA-Z" /=\-.0-9]*) alt="([a-zA-Z]*)"([a-zA-Z" /=\-.0-9]*)(\/)?>/gm,
      " $2 "
    );

    value = value.replace(/ {2,}/g, " ");

    const div = document.createElement("div");
    div.innerHTML = value;

    return div.textContent || div.innerText || "";
  };

  return { format, parse };
};

export default useMessage;
