import Modal from "../Modal";

type Props = {
  onClose: () => void;
};

function Tip({ onClose }: Props) {
  return (
    <Modal position="bottom" title="Doação" onClose={onClose}>
      <p>
        Envie uma doação para <b>rickybgs</b>:
      </p>
      <input type="text" placeholder="Valor" />
      <input type="text" placeholder="Mensagem" />
    </Modal>
  );
}

export default Tip;
