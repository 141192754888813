import Modal from "../Modal";
import Emote from "../Emote";
import { categorizedEmotes } from "../../data/emotes";
import styles from "./EmotePicker.module.scss";

type Props = {
  onPick: (emote: string) => void;
  onClose: () => void;
};

function EmotePicker({ onPick, onClose }: Props) {
  return (
    <Modal position="bottom" title="Emotes" onClose={onClose}>
      <div className={styles.emotes}>
        {Object.keys(categorizedEmotes).map((category) => (
          <div key={category} className={styles.category_container}>
            <p className={styles.title}>{category}</p>
            <div>
              {Object.keys(categorizedEmotes[category]).map((emote) => (
                <Emote
                  key={emote}
                  alias={emote}
                  onClick={(e) => {
                    e.preventDefault();
                    onPick(emote);
                  }}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </Modal>
  );
}

export default EmotePicker;
