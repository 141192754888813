export type EmoteConfig = {
  name: string;
  category: string;
  image: string;
};

const emotes: Record<string, EmoteConfig> = {
  cryPepe: { name: "cryPepe", category: "Pepe", image: "cry-pepe" },
  sadPepe: { name: "sadPepe", category: "Pepe", image: "sad-pepe" },
  scaredPepe: { name: "scaredPepe", category: "Pepe", image: "scared-pepe" },
  shoutPepe: { name: "shoutPepe", category: "Pepe", image: "shout-pepe" },
  smilePepe: { name: "smilePepe", category: "Pepe", image: "smile-pepe" },
  thuglifePepe: {
    name: "thuglifePepe",
    category: "Pepe",
    image: "thuglife-pepe",
  },
  wisePepe: { name: "wisePepe", category: "Pepe", image: "wise-pepe" },
  Banana: { name: "Banana", category: "LovePix", image: "Banana" },
  Zipper: { name: "Zipper", category: "LovePix", image: "Zipper" },
  MakeITRain: { name: "MakeITRain", category: "LovePix", image: "MakeITRain" },
  PAfraid: { name: "PAfraid", category: "PeachPix", image: "PAfraid" },
  PAss: { name: "PAss", category: "PeachPix", image: "PAss" },
  PBlink: { name: "PBlink", category: "PeachPix", image: "PBlink" },
  PBondage: { name: "PBondage", category: "PeachPix", image: "PBondage" },
  PCanTakeIt: { name: "PCanTakeIt", category: "PeachPix", image: "PCanTakeIt" },
  PCry: { name: "PCry", category: "PeachPix", image: "PCry" },
  PFlirt: { name: "PFlirt", category: "PeachPix", image: "PFlirt" },
  PHappy: { name: "PHappy", category: "PeachPix", image: "PHappy" },
  PHot: { name: "PHot", category: "PeachPix", image: "PHot" },
  PlayU: { name: "PlayU", category: "LovePix", image: "PlayU" },
  PMeasure: { name: "PMeasure", category: "PeachPix", image: "PMeasure" },
  POMG: { name: "POMG", category: "PeachPix", image: "POMG" },
  PShy: { name: "PShy", category: "PeachPix", image: "PShy" },
  PWet: { name: "PWet", category: "PeachPix", image: "PWet" },
  PYammy: { name: "PYammy", category: "PeachPix", image: "PYammy" },
  ShowBbs: { name: "ShowBbs", category: "LovePix", image: "ShowBbs" },
  ShowOff: { name: "ShowOff", category: "LovePix", image: "ShowOff" },
  SlapDat: { name: "SlapDat", category: "LovePix", image: "SlapDat" },
  TakeMyMoney: {
    name: "TakeMyMoney",
    category: "LovePix",
    image: "TakeMyMoney",
  },
};

const categorizedEmotesMap: Record<string, Record<string, EmoteConfig>> = {};

for (const emoteName of Object.keys(emotes)) {
  const emote = emotes[emoteName];
  if (!categorizedEmotesMap[emote.category]) {
    categorizedEmotesMap[emote.category] = {};
  }

  categorizedEmotesMap[emote.category][emoteName] = emote;
}

export const categorizedEmotes = categorizedEmotesMap;

export default emotes;
