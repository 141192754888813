type BadgeConfig = {
  name: string;
};

const badges: Record<string, BadgeConfig> = {
  staff: { name: "Staff" },
  broadcaster: { name: "Proprietário" },
  moderator: { name: "Moderador" },
  verified: { name: "Verificado" },
  subscriber: { name: "Assinante" },
};

export default badges;
