import React from "react";
import { Tooltip } from "react-tooltip";
import styles from "./Emote.module.scss";
import emotes from "../../data/emotes";

type Props = {
  messageId?: string;
  alias: string;
  onClick: (event: React.MouseEvent<HTMLSpanElement>) => void;
};

function Emote({ messageId, alias, onClick }: Props) {
  const emote = emotes[alias];

  return (
    <span className={styles.container} onClick={onClick}>
      <Tooltip
        content={emote.name}
        place="right"
        anchorId={`${messageId || "picker"}-emote-${alias}`}
      />
      <img
        id={`${messageId || "picker"}-emote-${alias}`}
        src={`/images/emotes/${emote.image}-56.png`}
        alt={emote.name}
      />
    </span>
  );
}

export default Emote;
