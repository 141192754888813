import React from "react";
import { useLocalStorage } from "usehooks-ts";
import { User } from "../types";
import useApi from "./useApi";

const useUser = () => {
  const api = useApi();

  const [user, setUser] = useLocalStorage<User | null>("user", null);

  React.useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    api.get<User>("/user", { signal }).then(({ data: user }) => setUser(user));

    return () => controller.abort();
  }, []);

  return user || null;
};

export default useUser;
