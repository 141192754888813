import React, { useRef } from "react";
import { Tooltip } from "react-tooltip";
import { ChatMessage } from "../../types";
import badges from "../../data/badges";
import styles from "./Message.module.scss";
import useMessage from "../../hooks/useMessage";

type Props = {
  message: ChatMessage;
};

function Message({ message }: Props) {
  const formatter = useMessage();

  const messageRef = useRef<HTMLSpanElement>(null);

  React.useEffect(() => {
    if (!messageRef.current) {
      return;
    }

    while (messageRef.current.firstChild) {
      messageRef.current.removeChild(messageRef.current.firstChild);
    }

    const elements = formatter.format(message.message);

    for (const element of elements) {
      if (typeof element === "string") {
        messageRef.current.appendChild(document.createTextNode(element + " "));
      } else {
        messageRef.current.appendChild(element);
      }
    }
  }, [message, messageRef]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div>
          <>
            {Object.keys(badges).map((badge) =>
              message.badges[badge] ? (
                <span key={badge} className={styles.badge}>
                  <Tooltip
                    content={badges[badge].name}
                    place="right"
                    anchorId={`${message.id}-badge-${badge}`}
                  />
                  <img
                    id={`${message.id}-badge-${badge}`}
                    src={`/images/badges/${badge}.png`}
                    alt={badges[badge].name}
                  />
                </span>
              ) : null
            )}
            <span
              className={styles.username}
              style={{ color: message.color }}
              onClick={() =>
                window.open(`https://lovepix.gg/${message.channel}`)
              }
            >
              {message.username}
            </span>
            :{" "}
            {message.deleted ? (
              <span className={styles.removed_message}>
                Mensagem removida por um moderador.
              </span>
            ) : (
              <span ref={messageRef} className={styles.message}></span>
            )}
          </>
        </div>
      </div>
    </div>
  );
}

export default Message;
