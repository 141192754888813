import styles from "./IconButton.module.scss";
import React from "react";

type Props = {
  icon: JSX.Element;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

function IconButton({ icon, onClick }: Props) {
  return (
    <button className={styles.button} onClick={onClick}>
      {icon}
    </button>
  );
}

export default IconButton;
