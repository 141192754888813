import React from "react";
import { Cog6ToothIcon } from "@heroicons/react/24/solid";
import { IRCHook } from "../../hooks/useIRC";
import useUser from "../../hooks/useUser";
import IconButton from "../IconButton";
import Settings from "../Settings";
import styles from "./Header.module.scss";

type Props = {
  irc: IRCHook;
};

function Header({ irc }: Props) {
  const user = useUser();

  const [showSettings, setShowSettings] = React.useState<boolean>(false);

  return (
    <>
      {showSettings && (
        <Settings irc={irc} onClose={() => setShowSettings(false)} />
      )}
      <div className={styles.container}>
        <div />
        <p>Chat</p>
        <div>
          {user && (
            <IconButton
              icon={<Cog6ToothIcon />}
              onClick={() => setShowSettings((current) => !current)}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default Header;
