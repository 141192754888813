import React from "react";
import { useDarkMode } from "usehooks-ts";
import { useParams, useSearchParams } from "react-router-dom";
import useIRC from "../../hooks/useIRC";
import useApi from "../../hooks/useApi";
import Header from "../Header";
import History from "../History";
import Input from "../Input";
import styles from "./Chat.module.scss";
import ConnectionStatus from "../ConnectionStatus";
import Notice from "../Notice";
import { ReadyState } from "react-use-websocket";

function Chat() {
  const { username } = useParams();
  const [searchParams] = useSearchParams();

  const api = useApi();
  const irc = useIRC();

  const [user, setUser] = React.useState<{ username: string; token: string }>();

  const {
    isDarkMode,
    enable: enableDarkMode,
    disable: disableDarkMode,
  } = useDarkMode();

  React.useEffect(() => {
    document.documentElement.setAttribute(
      "data-theme",
      isDarkMode ? "dark" : "light"
    );
  }, [isDarkMode]);

  React.useEffect(() => {
    if (searchParams.has("theme")) {
      const theme = searchParams.get("theme");

      if (theme === "dark") {
        enableDarkMode();
      } else {
        disableDarkMode();
      }
    }
  }, [searchParams, enableDarkMode, disableDarkMode]);

  React.useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    api
      .get<{ username: string; token: string }>(
        `/user/chat/token?channel=${username}`,
        { signal }
      )
      .then(({ data: { username, token } }) => setUser({ username, token }))
      .finally(() => {
        if (username) {
        }
      });

    return () => controller.abort();
  }, [username]);

  React.useEffect(() => {
    if (!username || irc.readyState !== ReadyState.OPEN) {
      return;
    }

    if (user) {
      irc.authenticate(user.username, user.token);
    }

    irc.join(username);
  }, [username, user, irc.readyState]);

  if (!username) {
    return null;
  }

  return (
    <div className={styles.container}>
      <Header irc={irc} />
      <div className={styles.content}>
        <div id="modal-wrapper" className={styles.modal_wrapper} />
        <History messages={irc.messages} />
      </div>
      <ConnectionStatus state={irc.readyState} />
      {irc.notice && <Notice message={irc.notice} />}
      <Input onSend={(message) => irc.send(username, message)} />
    </div>
  );
}

export default Chat;
