import { ReadyState } from "react-use-websocket";
import styles from "./ConnectionStatus.module.scss";

type Props = {
  state: ReadyState;
};

const messages: Record<ReadyState, string> = {
  [ReadyState.UNINSTANTIATED]: "Conectando ao chat...",
  [ReadyState.CONNECTING]: "Conectando ao chat...",
  [ReadyState.OPEN]: "Conectado",
  [ReadyState.CLOSING]: "Conexão perdida, tentando reconectar...",
  [ReadyState.CLOSED]: "Conexão perdida, tentando reconectar...",
};

function ConnectionStatus({ state }: Props) {
  if (state === ReadyState.OPEN) {
    return null;
  }

  return (
    <div className={styles.container}>
      <p>{messages[state]}</p>
    </div>
  );
}

export default ConnectionStatus;
