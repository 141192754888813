import Modal from "../Modal";
import styles from "./Settings.module.scss";
import { CirclePicker } from "react-color";
import { IRCHook } from "../../hooks/useIRC";
import { useParams } from "react-router-dom";

type Props = {
  irc: IRCHook;
  onClose: () => void;
};

function Settings({ irc, onClose }: Props) {
  const { username } = useParams();

  return (
    <Modal position="top" title="Configurações do chat" onClose={onClose}>
      <div className={styles.container}>
        <p className={styles.title}>Cor do nome</p>
        <p className={styles.legend}>
          Escolha uma cor para personalizar a exibição do seu nome.
        </p>
        <div className={styles.color_picker_container}>
          <CirclePicker
            width="100%"
            onChange={(color) => irc.send(username!, `/color ${color.hex}`)}
          />
        </div>
      </div>
    </Modal>
  );
}

export default Settings;
